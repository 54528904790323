import React,{Fragment} from 'react';
import {Link} from 'react-router-dom';
function Header(){
    return(
        <Fragment>
            <header>
            <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
                <div className="container-fluid">
                    <Link to={{pathname:"/"}}className="navbar-brand text__h2"> 
                        <img src="https://i.imgur.com/9xZGiqU.png" style={{width:"100px"}} alt="logo"/>
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarColor03" aria-controls="navbarColor03" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
            
                <div className="collapse navbar-collapse" id="navbarColor03">
                    <ul className="navbar-nav m-auto text__navbar text-capitalize">
                        <li className="nav-item">
                            <Link className="nav-link" to={"/"} style={{fontWeight: "bold"}}>Catalogo
                                <span className="visually-hidden">(current)</span>
                            </Link>
                        </li>
                    <li className="nav-item">
                            <Link className="nav-link text-capitalize" to={"/nosotros"}  style={{fontWeight: "bold"}}>Nosotros</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link text-capitalize" to={"/contacto"} style={{fontWeight: "bold"}} >Contactenos</Link>
                        </li>
                    </ul>
                    <div className="d-flex align-items-center justify-content-between col-2">
                        <Link to={{pathname:"https://www.facebook.com/BarsandPeru/"}} target="_blank"><i className="fab fa-facebook-f icon__navbar"></i></Link>
                        <Link to={{pathname:'https://www.instagram.com/barsand.peru/'}} target="_blank"><i className="fab fa-instagram icon__navbar"></i></Link>
                        <Link to={{pathname:'https://www.linkedin.com/company/barsand/'}} target="_blank"><i className="fab fa-linkedin icon__navbar"></i></Link>
                    </div>
                    <ul className="navbar-nav m-auto text__navbar text-capitalize">
                        <li className="nav-item">
                            <Link className="nav-link"to={{pathname:"https://vibrant-lamarr-96ceaa.netlify.app"}} 
                                  style={{background:"rgb(252, 186, 65)" ,color:"White", fontWeight: "bold"}}
                                  target="_parent"
                            >
                                Iniciar sesion
                            </Link>
                        </li>
                   
                    </ul>
                </div>
                </div>
            </nav>
       </header> 
        </Fragment>
    )
}
export default Header;