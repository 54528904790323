import React,{Fragment} from 'react';
import Header from '../Header';
import Bannernos from '../Bannernos';
import Footer from '../Footer';
import Wpss  from '../Wpss';

function Nosotros(){
   
    return(
        <Fragment>
            <Header />
            <Bannernos />
            
            <div className="container-fluid mb-5">
                <h1 className='text-center mt-4'>Nosotros</h1>

                <div className="col-11 d-flex-app m-auto justify-content-around mt-5 todo__ancho absolute">
                    <div className="col-4 bg-info p-4 todo__ancho">
                        <i className="far fa-lightbulb icon__nosotros"></i>
                        <h3 className="text-white mt-4 text-bold">Nuestra vision</h3>
                        <p className="text-white text-lowercase">
                            Ser una organización líder en nutrición clínica especializada, 
                            con un equipo calificado logrando ofrecer productos y servicios 
                            innovadores a nivel nacional e internacional, comprometidos a mejorar la calidad de vida de las personas.
                        </p>
                    </div>
                    <div className="col-4 bg-info p-4 todo__ancho">
                        <i className="fas fa-cog icon__nosotros"></i>
                        <h3 className="text-white mt-4 text-bold">Nuestra Mision</h3>
                        <p className="text-white text-lowercase">
                            Somos una organización innovadora en nutrición clínica 
                            especializada, comprometidos en generar productos y 
                            servicios de calidad, garantizando confianza y beneficios 
                            a nuestros clientes y colaboradores.
                        </p>
                    </div>
                    <div className="col-4 bg-info p-4 todo__ancho">
                        <i className="fas fa-cog icon__nosotros"></i>
                        <h3 className="text-white mt-4 text-bold">Nuestros Valores</h3>
                        <ul className="text-white text-lowercase">
                           <li>
                               Puntualidad
                           </li>
                           <li>
                               Compromiso
                           </li>
                           <li>
                               Respeto
                           </li>
                           <li>
                               Resiliencia
                           </li>
                        </ul>
                    </div>
                   
                </div>
                <div className="col-11 d-flex-app m-auto justify-content-around mt-5 todo__ancho flex-wrap">
                        <div className="col-5 d-flex-app justify-content-center todo__ancho">
                            <div className="col-5 ">
                                <i className="fas fa-comments icon__nosotros bg-info p-4"></i>
                            </div>
                            <div className="col-6 text-left">
                                <h4>Consulta 24/7</h4>
                                <p>
                                    Consulta todas tus dudas sobre algunos
                                    de nuestros productos
                                </p>
                            </div>
                        </div>
                        <div className="col-5 d-flex-app justify-content-center todo__ancho">
                            <div className="col-5">
                                <i className="fas fa-users icon__nosotros bg-info p-4"></i>
                            </div>
                            <div className="col-6 text-left">
                                <h4>Grupo Especializado</h4>
                                <p>
                                    somos grupo de personas especializada
                                    en cuidado nutricional.
                                </p>
                            </div>
                        </div>
                        <div className="col-5 d-flex-app justify-content-center todo__ancho">
                            <div className="col-5 ">
                                <i className="fas fa-thumbs-up icon__nosotros bg-info p-4"></i>
                            </div>
                            <div className="col-6 text-left">
                                <h4>Calidad 100%</h4>
                                <p>
                                   Nuestros productos 100% de calidad
                                </p>
                            </div>
                        </div>
                        <div className="col-5 d-flex-app justify-content-center todo__ancho">
                            <div className="col-5 ">
                                <i class="far fa-calendar icon__nosotros bg-info p-4"></i>
                            </div>
                            <div className="col-6 text-left">
                                <h4>Innovacion</h4>
                                <p>
                                   Innovamos para dar productos de calidad y originales
                                </p>
                            </div>
                        </div>
                </div>
            </div>
            <Wpss />
            <Footer />
        </Fragment>
    )
}
export default Nosotros