import React,{Fragment} from 'react';
import {Link} from 'react-router-dom';
import portada from '../imagenes/portada.png';

function Descargar(){
    return(
            <Fragment>
                <div className='container mb-4'>
                    <h1 className='text-center'>Informacion Adicional</h1>
                    <div className='col-10 m-auto d-flex-app justify-content-between align-items-center'>
                            <div className='col-4 todo__ancho'>
                            <Link to={{pathname:'https://barsand.com.pe/wp-content/uploads/2018/02/TRIPTICO-1-BARSAND-REDUCIDO-1-1.pdf'}} 
                                target="_blank">
                                    <img src={portada}  style={{width:'100%'}} className='efecto' alt="portada 1"/>
                                </Link>
                                <h5 className='mt-2'>Titulo: <span className='letra-diferencial'>Triptico de Producto</span></h5>
                                <h5 className='mt-2'>Creador: <span className='letra-diferencial'>Brasand International</span></h5>
                            </div>
                            <div className='col-2'>
                               <Link to={{pathname:'https://barsand.com.pe/wp-content/uploads/2018/02/TRIPTICO-1-BARSAND-REDUCIDO-1-1.pdf'}} 
                                target="_blank" className="btn btn-black btn-lg todo__ancho"> Descargar{" "} <i className="fas fa-download"></i></Link>
                            </div>
                       
                    </div>
                    <br />
                    
                </div>
            </Fragment>
    )
}

export default Descargar