import React,{Fragment} from 'react';
import {BrowserRouter as Router,Route,Switch} from 'react-router-dom';
import './App.css';
import Inicio from './componente/Inicio';
import Verid from './componente/Verid';
import Nosotros from './componente/Nosotros';
import Contacto from './componente/Contacto';
function App() {
  return (
    <Router>
      <Fragment>
        <Switch>
          <Route exact path="/">
              <Inicio />
          </Route>
          
          <Route exact path="/nosotros" component={Nosotros} />
          <Route exact path="/contacto" component={Contacto} />
          <Route exact path="/producto/:idProducto" component={Verid} />
        </Switch>
      </Fragment>
    </Router>
  )
}

export default App;
