import React,{Fragment,useState,useEffect} from 'react';
import Header from '../Header';
import Axios from '../config/axios';
import Formulario from '../Formulario';
import Footer from '../Footer';
import Wpss  from '../Wpss';
import {Link} from 'react-router-dom';

function Verid(props){
    const{idProducto} = props.match.params;
    const[producto,cargarProductos] = useState([]);
    const[formulario,formularioSet] = useState(false);

    
    const verFormulario = () =>{
       formularioSet(true)
    }
    useEffect(()=>{
        const consultarQuery = async()=>{
        const productosId = await Axios.get(`/producto/${idProducto}`);
        cargarProductos(productosId.data);
    }
        consultarQuery();
    },[idProducto]);
    return(
        <Fragment>
           <Header />
           <main className="container-fluid">
                <br />
                <h1 className="text-center mt-5 sin__bold text-capitalize">Producto escogido:<span className="text-capitalize">{producto.nombre}</span></h1>
                <div className="row col-12 align-items-center justify-content-center d-flex-app">
                    <div className="col-6">
                        <img src={producto.imagenUrl} alt="" className="img-fluid btn__ancho border__radius" />
                    </div>
                    <div className="col-4 todo__ancho">
                        <figure>
                            <h3 className="text-start text-uppercase">{producto.nombre}</h3>
                            <blockquote className="blockquote" style={{fontWeight: '300'}}>
                            <p className="texto__justificado">
                                {producto.descripcion}
                            </p>
                            </blockquote>
                        
                        </figure>
                        <ul className="list-group">
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                Nutrientes: {producto.nutrientes}
                            <span className="badge bg-success rounded-pill"><i className="fas fa-check"></i></span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                Presentacìon: {producto.presentacion} 
                            <span className="badge bg-success rounded-pill"><i className="fas fa-check"></i></span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                Sabor: {producto.sabor}
                            <span className="badge bg-success rounded-pill"><i className="fas fa-check"></i></span>
                            </li>
                        </ul>
                    </div>
                </div>
            </main>
            <main className="container-fluid">
                <div className="row col-10 m-auto todo__ancho">
                    <h3 className="text-start text-uppercase mt-5 text-black">descripcion del producto</h3>
                  
                    <ul className="list-group">
                        <li className="list-group-item d-block">
                            <h4>Forma de uso:</h4>
                            <p>
                                {producto.formaUso}
                            </p>
                        </li>
                        <li className="list-group-item d-block">
                            <h4>Precauciones:</h4>
                            <p>
                                {producto.precauciones}
                            </p>
                        </li>
                        <li className="list-group-item d-block">
                            <h4>Contraindicaciones:</h4>
                            <p>
                                {producto.contraindicaciones}
                            </p> 
                        </li>
                    </ul>
                    
                    <div className="modal-footer d-flex-app  justify-content-end
                     cero__box todo__ancho">
                        <button 
                            type="button" 
                            className="btn btn-info btn-lg todo__ancho"
                            onClick={verFormulario}
                        >
                            Registrar consulta{" "} 
                            <i className="far fa-file-alt"></i>
                        </button>
                        <Link to={{pathname:'https://card-digital-barsand.netlify.app/'}} 
                              className="btn btn-warning btn-lg todo__ancho"
                              target="_blank"
                        >Hablar con representante <i className="fas fa-user-tie"></i></Link>
                      <Link to={{pathname:`${producto.pdfUrl}`}} target="_blank" className="btn btn-black btn-lg todo__ancho"> Descargar tabla nutricional{" "} <i className="fas fa-download"></i></Link>
                    </div>
                </div>
            </main>
          
            {formulario ? <div className="fondo__regitro p-4"><Formulario producto={producto.nombre}  formulario={formulario} /></div>:<p></p>}
            <Wpss />
            <Footer />
        </Fragment>
    )
}

export default Verid;