import React, {Fragment} from 'react';
import Header from '../Header';
import Banner from '../Banner';
import Nutrientes from '../Nutrientes';
import Producto from '../Producto';
import Footer from '../Footer';
import Wpss  from '../Wpss';
import Descargar from './Descargar';
function Inicio(){
    return(
        <Fragment>
            <Header />
            <Banner />
            <div className="container-fluid">
                <div className="col-11 m-auto mt-5">
                    <h3 className="text-center mt-6 mb-2 p-3">En <small style={{fontWeight: 'bold'}} className="blue-text">Barsand International</small>, 
                        innovamos en nutrición desarrollando 
                        productos con la última tecnología, 
                        lo más importante para nosotros es la 
                        calidad de vida de las personas.
                    </h3>
                </div>
                <iframe className="todo__ancho todo__alto" title="vimeo-player" src="https://player.vimeo.com/video/613659671?h=3469aa10a6%20dnt=1%20app_id=122963%20autoplay=1" style={{width:'100%'}} height="400px"></iframe>
            </div>
            <Nutrientes />
            
            <Producto />
            <Descargar />
            <Wpss />
            <Footer />
        </Fragment>
    )
}

export default Inicio;
