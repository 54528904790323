import React,{Fragment} from 'react';
import {Link} from 'react-router-dom';
function Banner(){

    return(
        <Fragment>
            <div className="parallax img-fluid todo__alto">
                <div className="container-fluid">
                    <div className="box">
                        <div className="row gx-5 mt-5">
                            <div className="col-9">
                                <div className="p-3 mt-5 media__mt">
                                    <h1 className="titulo__hero text-start text-uppercase text-white todo__ancho">Barsand International</h1>
                                    <div className="col-8 me-auto">
                                        <p className="texto__hero text-start text-white todo__ancho">
                                           Empresa especializada en nutrición
                                        </p>
                                        <Link to={"/contacto"} 
                                            className="btn btn-info text__btn btn-lg todo__ancho p-3"
                                        >
                                            Contactanos
                                        </Link>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Banner;