import React,{Fragment,useState} from 'react';
import { withRouter } from 'react-router-dom'; 
import Axios from './config/axios';
import Swal from 'sweetalert2';
import imagen from './imagenes/emailtres.svg';
function Formularioemail({history}){
    const[emails,resgitrarEmails]=useState({
        nombre:"",
        correo:"",
        consulta:""
    });
    const actulaizarState=(e)=>{
        resgitrarEmails({
            //obtener la copia del stateActual
            ...emails,
            [e.target.name]:e.target.value
        })
    }

    const Registrar = (e)=>{
        e.preventDefault();
        Axios.post('/email',emails)
        .then(res=>{
            //validamos si hay errores de mongo
            if(res.data.code === 11000){
                Swal.fire({
                    icon:'error',
                    title:'Hubo un error',
                    text:'Falla al registrar tu email'
                })
            }else{
                Swal.fire(
                    'Tu email enviado',
                    res.data.mensaje,
                    'success'
                  )
                  //redireccionar
                history.push("/");
            }
            
        });
    }
    const validarCliente = () =>{
        const {nombre,correo,consulta} = emails;
        //revisar que el formulario tenga datos para enviar
        let valido =!nombre.length || !correo.length || !consulta.length;
        //retorna V o F
        return valido;
    }
    return(
        <Fragment>
          <h1 className='text-center mt-4'>Contactenos</h1>
         <div className='col-10 bg-primary m-auto mb-4 d-flex-app justify-content-center p-3 container__media'>
            <div className='col-4 bg-info bord-der p-3'>
              <img src={imagen} style={{width:'100%',marginTop:'100px'}} alt='email1' />
            </div>
            <div className='col-5 bg-white bord-izq'>
                    <form className='p-3'>
                            <div className="form-group mb-3">
                                        <label for="exampleInputEmail1" className="text-black">Nombre:</label>
                                        <input 
                                            type="text" 
                                            className="form-control btn__ancho" 
                                            name="nombre"
                                            placeholder="Tu nombre" 
                                            onChange={actulaizarState}
                                        />
                                        <small className="form-text text-muted text-black">
                                            *Obligatorio.</small>
                            </div>
                            <div className="form-group mb-3">
                                <label for="exampleInputEmail1 text-black" className="text-black">Corrreo:</label>
                                <input 
                                    type="email" 
                                    className="form-control btn__ancho " 
                                    name="correo" 
                                    placeholder="Tu correo" 
                                    onChange={actulaizarState}
                                />
                                <small id="emailHelp" class="form-text text-muted text-black">
                                    *Obligatorio.</small>
                            </div>
                            <div className="form-group mb-3">
                                <label for="exampleInputEmail1 text-black" className="text-black">Consulta:</label>
                                <textarea
                                    type="text" 
                                    className="form-control btn__ancho" 
                                    name="consulta" 
                                    placeholder="Tu consulta"
                                    onChange={actulaizarState}
                                    style={{maxHeight:'200px',minHeight:'200px'}}
                                />
                                <small  className="form-text text-muted text-black">
                                    *Obligatorio.</small>
                            </div>
                            <button 
                                    type="submit" 
                                    className="btn btn-info btn__ancho btn-lg"
                                    onClick={Registrar}
                                    style={{width:'100%',textAlign:'center'}}
                                    disabled={validarCliente()}
                                >
                                    Registrar consulta
                            </button>
                    </form>
            </div>
         </div>
          
        </Fragment>
    )
}
export default withRouter(Formularioemail)