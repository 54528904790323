import React,{Fragment} from 'react';

function Bannernos(){

    return(
        <Fragment>
            <div className="parallax-no img-fluid todo__alto">
                <div className="container-fluid">
                <div className="box">
                        <div className="row gx-5 mt-5">
                            <div className="col-12 m-auto">
                                <div className="p-3 mt-5 media__mt">
                                    <h1 className="titulo__hero text-start text-uppercase text-white todo__ancho">Barsand International Nosotros</h1>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Bannernos;