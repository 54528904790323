import React,{Fragment} from 'react';
import {Link} from 'react-router-dom'; 

 function Wpss(){
     return(
        <Fragment>
            <Link  to={{pathname:`https://wa.me/51961475546?text=Me%20gustaría%20saber%20mas%20sobre%20el%20%20producto%20`}} className="whatsapp" 
            target="_blank"> <i class="fa fa-whatsapp whatsapp-icon"></i></Link>
        </Fragment>
     )
 }
 export default Wpss