import React,{Fragment} from 'react';
import {Link} from 'react-router-dom';
function Footer(){
    return(
        <Fragment>
            <footer className="text-center text-lg-start bg-dark text-muted">
   
                <section
                    className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom"
                >
                    
                    <div className="me-5 d-none d-lg-block">
                    <span>Nuestras redes sociales:</span>
                    </div>
                    
                    <div>
                        <Link to={{pathname:'https://www.facebook.com/BarsandPeru/'}} className="me-4 text-reset text-decoration-none" target="_blank">
                            <i className="fab fa-facebook-f"></i>
                        </Link>
                        <Link to={{pathname:'https://www.instagram.com/barsand.peru/'}} className="me-4 text-reset text-decoration-none" target="_blank">
                            <i className="fab fa-instagram"></i>
                        </Link>
                        <Link to={{pathname:'https://www.linkedin.com/company/barsand/'}} className="me-4 text-reset text-decoration-none" target="_blank">
                            <i className="fab fa-linkedin"></i>
                        </Link>
                        
                    </div>
                    
                </section>
                
                
                
                <section className="">
                    <div className="container text-center text-md-start mt-5">
                    
                    <div className="row mt-3">
                        
                        <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                        
                        <h6 className="text-uppercase fw-bold mb-4">
                            <i className="fas fa-gem me-3"></i>Barsand International
                        </h6>
                        <p>
                            En Barsand International, innovamos en nutrición desarrollando productos con la última tecnología, lo más importante para nosotros es la calidad de vida de las personas.
                        </p>
                        </div>
                        
                
                        
                        <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                        
                        <h6 className="text-uppercase fw-bold mb-4">
                            Nuestros productos
                        </h6>
                        <p>
                            <p className="text-reset text-decoration-none">Procel</p>
                        </p>
                        <p>
                            <p className="text-reset text-decoration-none">Provide Gold</p>
                        </p>
                        <p> 
                            <p className="text-reset text-decoration-none">Liquacel</p>
                        </p>
                        <p>
                            <p className="text-reset text-decoration-none">Fibercel</p>
                        </p>
                        </div>
                        
                
                        
                
                        
                        <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                        
                        <h6 className="text-uppercase fw-bold mb-4">
                            Nuestros contactos
                        </h6>
                        <p><i className="fas fa-home me-3 text-lowercase"></i>JR. CAMINO REAL Nro 1801 Dpto B14 Interior 2 . Santiago de Surco</p>
                        <p>
                            <i className="fas fa-envelope me-3"></i>
                            contacto@barsand.com.pe
                        </p>
                        <p><i className="fas fa-phone me-3"></i>(01) 683-1245</p>
                        
                        </div>
                        
                    </div>
                    
                    </div>
                </section>
                
                
                
                <div className="text-center p-4" style={{backgroundColor: 'rgba(0, 0, 0, 0.05)'}}>
                    © 2021 Copyright:
                    <Link className="text-reset fw-bold text-decoration-none" to={{pathname:'#'}}>Portafoliobrandon</Link>
                </div>
                
                </footer>
        </Fragment>
    )
}
export default Footer