import React,{Fragment, useState} from 'react';
import { withRouter } from 'react-router-dom'; 
import Axios from './config/axios';
import Swal from 'sweetalert2';

function Formulario({history,producto}){
   
    const[registro,nuevoRegistro]=useState({
        nombre:'',
        apellido:'',
        producto:'',
        residencia:'',
        profesion:'',
        cmp_cnp:'',
        telefono:'',
        correo:'',
        consulta:''
    })
    
    const actulaizarState = (e) =>{
        nuevoRegistro({
            //obtener la copia del stateActual
            ...registro,
            [e.target.name]:e.target.value
        })
    }
    const Registrar = (e) =>{
        e.preventDefault();
        Axios.post('/registro',registro)
        .then(res=>{
            //validamos si hay errores de mongo
            if(res.data.code === 11000){
                Swal.fire({
                    icon:'error',
                    title:'Hubo un error',
                    text:'Falla al registrar tu consulta'
                })
            }else{
                Swal.fire(
                    'Se a registrado tu consulta',
                    res.data.mensaje,
                    'success'
                  )
                  //redireccionar
                history.push(`/`);
            }
            
        });
        
    }
    const validarCliente = () =>{
        const {nombre,apellido,residencia,profesion,cmp_cnp,telefono,correo,producto,consulta} = registro;
        //revisar que el formulario tenga datos para enviar
        let valido =!nombre.length || !apellido.length || !residencia.length || !profesion.length 
        || !cmp_cnp.length || !telefono.length || !correo.length || !producto.length || !consulta.length;
        //r
        return valido;
    }
    return(
        <Fragment>
           <div className="col-5 m-auto mb-3 box-sha">
               <h2 className="text-center text-uppercase mb-3 mt-2">Registrar contulta</h2>
           <form className="letra p-4">
                    <div className="form-group mb-3">
                        <label for="exampleInputEmail1" className="text-black">Nombre:</label>
                        <input 
                            type="text" 
                            className="form-control btn__ancho border__radius" 
                            name="nombre"
                            placeholder="Tu nombre" 
                            onChange={actulaizarState}
                        />
                        <small id="emailHelp" class="form-text text-muted text-black">
                            *Obligatorio.</small>
                    </div>
                    <div className="form-group mb-3">
                        <label for="exampleInputEmail1 text-black" className="text-black">Apellido:</label>
                        <input 
                            type="text" 
                            className="form-control btn__ancho border__radius" 
                            name="apellido" 
                            placeholder="Tu apellido" 
                            onChange={actulaizarState}
                        />
                        <small id="emailHelp" class="form-text text-muted text-black">
                            *Obligatorio.</small>
                    </div>
                    <div className="form-group mb-3">
                        <label for="exampleInputEmail1 text-black" className="text-black">Producto:</label>
                        <input 
                            type="text" 
                            className="form-control btn__ancho border__radius" 
                            name="producto" 
                            placeholder={`Escribe tu producto a consultar ${producto}`}
                            onChange={actulaizarState}
                        />
                        <small id="emailHelp" class="form-text text-muted text-black">
                            *Obligatorio.</small>
                    </div>
                    <div className="form-group mb-3">
                        <label for="exampleInputEmail1 text-black" className="text-black">Lugar de residencia:</label>
                        <input 
                            type="text" 
                            className="form-control btn__ancho border__radius" 
                            name="residencia"
                            placeholder="Tu lugar de residencia" 
                            onChange={actulaizarState}
                        />
                        <small id="emailHelp" class="form-text text-muted text-black">
                            *Obligatorio.</small>
                    </div>
                    
                    <div className="form-group mb-3">
                        <label for="exampleInputEmail1 text-black" className="text-black">Profesion:</label>
                        <input 
                            type="email" 
                            className="form-control btn__ancho border__radius" 
                            name="profesion" 
                            placeholder="Tu profesion"
                            onChange={actulaizarState}
                        />
                        <small id="emailHelp" class="form-text text-muted text-black">
                            *Obligatorio.</small>
                    </div>
                    <div className="form-group mb-3">
                        <label for="exampleInputEmail1 text-black" className="text-black">CMP/CNP:</label>
                        <input 
                            type="text" 
                            className="form-control btn__ancho border__radius" 
                            name="cmp_cnp"
                            placeholder="Tu codigo CMP/CNP" 
                            onChange={actulaizarState}
                        />
                        <small id="emailHelp" class="form-text text-muted text-black">
                            *Obligatorio.</small>
                    </div>
                    <div className="form-group mb-3">
                        <label for="exampleInputEmail1 text-black" className="text-black">Celular:</label>
                        <input 
                            type="text" 
                            className="form-control btn__ancho border__radius" 
                            name="telefono" 
                            placeholder="Tu celular"
                            onChange={actulaizarState} 
                        />
                        <small id="emailHelp" class="form-text text-muted text-black">
                            *Obligatorio.</small>
                    </div>
                    <div className="form-group mb-3">
                        <label for="exampleInputEmail1 text-black" className="text-black">Correo:</label>
                        <input 
                            type="text" 
                            className="form-control btn__ancho border__radius" 
                            name="correo" 
                            placeholder="Tu correo electronico"
                            onChange={actulaizarState} 
                        />
                        <small id="emailHelp" class="form-text text-muted text-black">
                            *Obligatorio.</small>
                    </div>
                    <div className="form-group mb-3">
                        <label for="exampleInputEmail1 text-black" className="text-black">Consulta:</label>
                        <input 
                            type="text" 
                            className="form-control btn__ancho border__radius" 
                            name="consulta" 
                            placeholder="Tu consulta"
                            onChange={actulaizarState} 
                        />
                        <small id="emailHelp" class="form-text text-muted text-black">
                            *Obligatorio.</small>
                    </div>
                    
                    
                        <button 
                            type="submit" 
                            className="btn btn-info text__btn d-flex align-items-end mt-3 todo__ancho text-center"
                            onClick={Registrar}
                            disabled={validarCliente()}
                            style={{width:'100%',textAlign:'center'}}
                        >
                            Registrar consulta
                        </button>
                  
                        
            </form>

           </div>
        </Fragment>
    )
}

export default withRouter(Formulario);