import React,{Fragment} from 'react';
 function Nutrientes(){
     return(
        <Fragment>
            <main className="container-fluid p-3 container__media">
                    <h2 className="text-center mt-4">Nuestros Nutrientes</h2>
                    <div className="row col-12 d-flex flex-wrap justify-content-evenly completo mb-4 align-items-center">
                        <div className="col-md-4 mt-3">
                            <img  loading="lazy" src="https://i.imgur.com/PCQ1yY3.png" className="img-fluid img__proteina border__radius" alt="imagen1" />
                        </div>
                        <div className="col-4  mt-3 todo__ancho">
                            <section>
                                <h3 className="titulo__nutrientes">Proteinas</h3>
                                <p className="text__nutrientes">
                                Las proteínas son nutrientes presentes en los alimentos, están formadas por 
                                moléculas pequeñas llamadas aminoácidos. Tienen funciones importantes en el 
                                organismo como fortalecer las defensas, regeneración de tejidos, músculos, entre otras.
                                </p>
                            </section>
                            <section>
                                <h3 className="titulo__nutrientes">Fibras</h3>
                                <p className="text__nutrientes">
                                La fibra soluble, es un carbohidrato que forma parte de una 
                                alimentación saludable. La fibra ayuda a prevenir el estreñimiento, 
                                controlar los niveles de glucosa, reducir los niveles de colesterol, 
                                controlar la ansiedad entre otras funciones.
                                </p>
                            </section>
                        </div>
                            

                    
                        
                    </div>
                </main>
        </Fragment>
     )
 }
 export default Nutrientes;