import React,{Fragment,useState,useEffect} from 'react';
import Axios from './config/axios';
import {Link} from 'react-router-dom'; 

function Producto(){
    const[producto,cargarProductos] = useState([]);
 
   
    const consultarQuery = async()=>{
        const productos = await Axios.get('producto');
        cargarProductos(productos.data);
    }
 
    useEffect(()=>{
        consultarQuery();
    },[]);


    return(
        <Fragment>
        <main className="container-fluid mt-5">
            <div className="row">
                
                <div className="col-12 mb-5 d-flex justify-content-evenly flex-wrap todo__ancho">
                    {
                        producto.map(proteinas =>( 
                            <div  key={proteinas._id} className="card  todo__ancho" style={{width:'15rem'}}>
                            <img loading="lazy" src={proteinas.imagenUrl} className="card-img-top " alt={proteinas.nombre} />
                                <div className="card-body">
                                    <h4 className="card-text text-center">{proteinas.nombre}</h4>
                                    <span className="badge rounded-pill bg-info mb-3">{proteinas.categoria}</span>
                                    <p>Sabor:<small style={{fontWeight:'bold'}}>{proteinas.sabor}</small></p>
                                     <Link to={`/producto/${proteinas._id}`} className="btn btn-info btn__ancho btn-lg" style={{borderRadius:'10px'}} > ver producto</Link>
                                  
                                    
                                </div>
                        </div> 
                        ))
                    }
                   
                </div>
            </div>
        </main>
        </Fragment>
    )
}
export default Producto;