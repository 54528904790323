import React,{Fragment} from 'react';
import Header from '../Header';
import Bannercon from '../Bannercon';
import Footer from '../Footer';
import Formularioemail from '../Formularioemail';
import Wpss from '../Wpss';

function Contacto(){
    return(
        <Fragment>
            <Header />
            <Bannercon />
            <Formularioemail />
            <Wpss />
            <Footer />
        </Fragment>
    )
}
export default Contacto